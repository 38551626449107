import React from 'react'
import ServiceList from '../serviceList'
import EyebrowHeader from '../eyebrowHeader'

export default function ServicesSection() {
  return (
    <div>
        <section>
            <div class="container py-10 mx-auto">
                <EyebrowHeader left text="Our Services" />
                <h2 class="text-3xl md:text-5xl lg:text-6xl md:mb-32 font-playFair md:w-3/4 py-4">We provide the <em>best solutions</em> for your build</h2>
                <ServiceList />
            </div>
        </section>
    </div>
  )
}
