import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { Link } from 'gatsby';

export const query = graphql`
  {
    services: allStrapiService {
      nodes {
        slug
        title
        childStrapiServiceDescriptionTextnode {
          description
        }
        icon {
          localFile {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
      }
    }
  }
`

export default function ServiceList() {
  const data = useStaticQuery(query)
  const services = data.services.nodes
  return (
    <div className='grid lg:grid-cols-2 md:grid-cols-3 gap-12'>
      {services?.map(service => {
            return (
              <Link to={"/services/" + service.slug} class="border border-primary group">
              <div>
                  <span class="inline-block">
                      <GatsbyImage
                          image={getImage(service?.icon?.localFile)}
                          class="w-full md:h-52"
                          objectFit='cover'
                      />
                  </span>
                  <div className="px-4 lg:px-12 py-6">
                      <h3 class="text-xl md:text-3xl 2xl:text-4xl font-playFair group-hover:text-primary">{service?.title}</h3>
                      <p class="text-sm md:text-lg text-gray-500">{service?.childStrapiServiceDescriptionTextnode?.description}</p>
                  </div>
              </div>
          </Link>
            )
        })
      }
    </div>
  )
}
