import React from "react";
import Layout from "../components/layout";
import SEO from "../components/seo";
import AboutUsSection from "../components/sections/aboutUs";
import ServicesSection from "../components/sections/services";
import WorksSection from "../components/sections/works";
import Metrics from "../components/metrics";
import AwardHero from "../components/awardHero";
import PageHeader from "../components/pageHeader";

function IndexPage() {
  return (
    <Layout>
      <SEO
        //keywords={[`gatsby`, `tailwind`, `react`, `tailwindcss`]}
        title="Live The Dream | Crafting Interior Solutions| Home"
      />
      <PageHeader
        title="We craft interior solutions"
        subTitle="Our design studio consists of a multi-functional production facility spanning 50,000 sq. ft., housed with state of the art machinery, and supported with a 3,000sq. ft. sales office"
      />
      <AwardHero full={true}/>
      <AboutUsSection />
      <Metrics />
      <div className='container mx-auto border-t border-tertiary my-20' />
      <ServicesSection />
      <WorksSection />

    </Layout>
  );
}

export default IndexPage;
